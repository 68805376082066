type Data = Record<string, any>;

export default class Report {
    data: Data

    pages: Array<string>

    pageData: Data

    pageComponents: Data

    componentsData: Data

    reportTitle: String

    reportClient: String

    constructor(data: Record<string, any>) {
        this.data = data;
        this.pages = [];
        this.pageData = {};
        this.pageComponents = {};
        this.componentsData = {};
        this.reportTitle = data.title;
        this.reportClient = data.client;

        data.pages.forEach((page: Data) => {
            this.pages.push(page.pid);
            this.pageData[page.pid] = {
                pid: page.pid,
                title: page.title,
            };

            const pageComponents: Array<Data> = [];

            page.components.forEach((com: Data) => {
                pageComponents.push({ cid: com.cid, key: com.key });
                this.componentsData[com.cid] = com.data;
            });

            this.pageComponents[page.pid] = pageComponents;
        });
    }

    public getPages() : Array<String> {
        return this.pages;
    }

    public getPageData() : Data {
        return this.pageData;
    }

    public getPageComponents() : Data {
        return this.pageComponents;
    }

    public getComponentsData() : Data {
        return this.componentsData;
    }

    public getTitle() : String {
        return this.reportTitle;
    }

    public getClient() : String {
        return this.reportClient;
    }

    public getClientKey() : String {
        return this.reportClient;
    }
}
