<template>
    <v-list-item link @click="$emit('click', page.pid)" :class={active,}>
        <v-list-item-content v-if="editMode">
            <v-text-field outlined dense @focusout="saveTitle" v-model="title" />
        </v-list-item-content>

        <v-list-item-content v-if="!editMode">
            <v-list-item-title>
                <v-icon x-small left class="drag-handle">fas fa-grip-lines</v-icon> {{ page.title }}
            </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
            <v-item-group v-if="!editMode">
                <v-btn icon x-small @click="editMode = true">
                    <v-icon color="grey lighten-1" x-small>{{ '$edit'  }}</v-icon>
                </v-btn>
                <v-btn icon x-small @click="$emit('on-delete', page.pid)">
                    <v-icon color="grey lighten-1" x-small>{{ '$delete'  }}</v-icon>
                </v-btn>
            </v-item-group>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
    export default {
        name: 'ReportPageItem',

        props: {
            page: {
                type: Object,
                required: true,
            },
            active: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            editMode: false,
            title: undefined,
        }),

        watch: {
            page: {
                handler: function handler(newValue) {
                    this.title = newValue.title;
                },
                immediate: true,
            },
        },

        methods: {
            saveTitle() {
                this.editMode = false;
                this.$emit('on-title-change', this.page.pid, this.title);
            },
        },
    };
</script>

<style scoped>
    .active {
        background: #E0F2F1;
    }

    .drag-handle{
        color: #bebebe;
        cursor: move;
    }
</style>
