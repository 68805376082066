<template>
    <builder :reportId="reportId" @on-create="onReportCreate" />
</template>

<script>
    import Builder from '@/components/Report/Builder/Builder.vue';

    export default {
        name: 'TestReportBuilderPage',

        components: {
            Builder,
        },

        props: {
            reportId: {
                type: String,
                default: undefined,
            },
        },

        methods: {
            onReportCreate(reportId) {
                this.$router.replace({ name: 'manage-report-builder', params: { reportId } });
            },
        },
    };
</script>
