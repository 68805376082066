<template>
    <div>
        <v-menu
            top
            v-model="visible"
            :close-on-content-click="false"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    icon
                    small
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon small>$add</v-icon>
                </v-btn>
            </template>

            <v-list>
                <v-list-item>
                    <v-list-item-title>Add page</v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <i-text-field :rules="rulesRequired" label="Page Title" v-model="title" />
                </v-list-item>
                <v-list-item>
                    <v-btn @click="addPage" small>Add</v-btn>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
    import { rules } from '@/utils';

    export default {
        name: 'CreateReportPage',

        data: () => ({
            title: undefined,
            visible: false,
            rulesRequired: [rules.required()],
        }),

        methods: {
            addPage() {
                // Unique Page id
                const timestamp = Date.now();
                const pid = `page-${timestamp}`;
                if (this.title) {
                    this.$emit('on-create', { title: this.title, pid, components: [] });
                }
                this.visible = false;
                this.title = undefined;
            },
        },
    };
</script>
