<template>
    <request :request="request" @on-success="onRequestSuccess">
        <v-list dense>
            <v-subheader>CUSTOM WIDGETS</v-subheader>
            <v-list-item-group
                v-model="selectedItem"
                color="primary"
            >
                <v-list-item
                    v-for="(widget, i) in widgets"
                    :key="i"
                >
                    <v-list-item-icon>
                        <v-chip small :ripple="false" dark>
                            <b>{{ widget.widget_type.toUpperCase() }}</b>
                        </v-chip>
                    </v-list-item-icon>
                    <v-list-item-content class="text-left">
                        <v-list-item-title v-text="widget.name" />
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </request>
</template>

<script>
    import ReportAPI from './webapi';

    export default {
        name: 'ClientWidgets',

        props: {
            clientKey: {
                type: String,
                required: true,
            },
        },

        data: () => ({
            selectedItem: undefined,
            widgets: [],
        }),

        computed: {
            request() {
                const { clientKey } = this;
                return () => ReportAPI.listClientWidgets(clientKey);
            },
        },

        methods: {
            onRequestSuccess(data) {
                this.widgets = data;
            },
        },
    };
</script>
