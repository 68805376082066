<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
                <v-icon small>{{ '$edit' }}</v-icon>
            </v-btn>
            <i-delete-btn title="Do you really want to delete the component?" @on-confirm="removeComponent" />
        </template>

        <v-card>
            <v-card-title class="headline grey lighten-2">
                <div>{{ component.name }} <small>{{ idx  }}</small></div>
            </v-card-title>

            <v-card-text>
                <v-card-text>
                    <v-row>
                        <v-col cols=12 v-for="field in component.fields" :key="field.key">
                            <i-text-field
                                v-if="field.kind === 'String'"
                                v-model="componentDataInternal[field.key]" :label="field.label"/>
                            <text-area
                                v-if="field.kind === 'TextArea'"
                                v-model="componentDataInternal[field.key]"
                                :label="field.label" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="primary"
                    text
                    @click="dialog = false"
                >
                    Done
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import bus from '@/ebus';
    import TextArea from './TextArea.vue';

    export default {
        name: 'ComponentParams',

        components: {
            TextArea,
        },

        props: {
            component: {
                type: Object,
                required: true,
            },
            componentData: {
                type: Object,
                default: () => {},
            },
            idx: {
                type: String,
                required: true,
            },
            pageId: {
                type: String,
                required: true,
            },
            open: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            componentKey: undefined,
            componentDataInternal: {},
            dialog: false,
        }),

        created() {
            // Event bus: if report component is created, open modal instantly.
            bus.$on('report-component-new', (componentId) => {
                console.log('Listener: report new', componentId, this.idx);
                if (componentId === this.idx) {
                    this.dialog = true;
                }
            });
        },

        watch: {
            componentData: {
                handler: function handler(newValue) {
                    this.componentDataInternal = newValue || {};
                },
                immediate: true,
            },
            componentDataInternal(newValue) {
                this.$emit('on-update', newValue, this.idx);
            },
            open: {
                immediate: false,
                handler(newValue) {
                    if (newValue === true) {
                        this.dialog = true;
                    }
                },
            },
        },

        methods: {
            removeComponent() {
                this.$emit('on-remove', { pid: this.pageId, cid: this.idx });
            },
        },
    };
</script>
