<template>
    <request :request="request" @on-success="onRequestSuccess">
        <v-row>
            <v-col cols=6>
                <v-row>
                    <v-col cols=12 class="text-left">
                        <v-btn color="secondary" @click="saveReport">
                            <v-icon left small>$save</v-icon>
                            Save
                        </v-btn>
                        <v-btn @click="previewReport" color="secondary" outlined class="ml-2">
                            <v-icon left small>$view</v-icon> Preview
                        </v-btn>

                        <v-menu
                            offset-y
                            nudge-right
                            close-on-click
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="ml-2"
                                    color="secondary"
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined

                                >
                                    <v-icon small left>far fa-comment-dots</v-icon> Notify
                                </v-btn>
                            </template>

                            <v-list dense>
                                <v-list-item link>
                                    <v-list-item-title
                                        @click="notificationSendMode = 'clients'; notificationSendModal = true;">
                                        Notify Client users
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item link>
                                    <v-list-item-title
                                        @click="notificationSendMode = 'author'; notificationSendModal = true;">
                                        Notify Yourself
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                    </v-col>
                    <v-col cols=8>
                        <i-text-field :rules="rulesRequired" v-model="reportTitle" label="Title" />
                    </v-col>
                    <v-col cols=4>
                        <client-selector
                            v-model="reportClient"
                            :rules="rulesRequired" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row  class="">
            <v-col cols=4 class=" text-left">
                <v-card tile>
                    <v-card-title class="text-left">
                        Pages
                        <page-create @on-create="pageCreate"/>
                    </v-card-title>
                    <v-card-text>
                        <v-list dense>
                            <draggable v-model="pages" group="people" @start="drag=true" @end="drag=false" handle=".drag-handle">
                                <page-item
                                    :active="page === selectedPageId"
                                    v-for="page in pages"
                                    :key="page"
                                    :page="pageData[page]"
                                    @click="pageSelect"
                                    @on-title-change="pageTitleUpdate"
                                    @on-delete="pageDelete" />
                            </draggable>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols=8 class="">
                <div style="max-width: 800px">
                    <v-card tile color="#E0F2F1" elevation=1 class="text-left">
                        <v-card-title>{{ selectedPageTitle }} - Components</v-card-title>
                        <div v-if="pageComponents[selectedPageId]">
                            <v-simple-table>
                                <draggable
                                    tag="tbody"
                                    :list="pageComponents[selectedPageId]"
                                    group="components"
                                    @start="drag=true"
                                    @end="drag=false"
                                    handle=".drag-handle-comp">
                                    <tr v-for="component in pageComponents[selectedPageId]" :key="component.cid">
                                        <td>
                                            <v-icon x-small left class="drag-handle-comp">fas fa-grip-lines</v-icon>
                                        </td>
                                        <td>
                                            <div v-if="selectedComponentsData[component.cid]">
                                                {{ selectedComponentsData[component.cid].name }}
                                                <div><small>{{ component.cid }}</small></div>
                                            </div>
                                        </td>
                                        <td class="">
                                            <v-list-item-title>
                                                <v-chip small>{{ availableComponents[component.key].name }}</v-chip>
                                            </v-list-item-title>
                                        </td>
                                        <td class="text-right">
                                            <component-params
                                                :component="availableComponents[component.key]"
                                                :component-data="selectedComponentsData[component.cid]"
                                                :idx="component.cid"
                                                :pageId="selectedPageId"
                                                @on-remove="componentRemove"
                                                @on-update="componentUpdate" />
                                        </td>
                                    </tr>
                                </draggable>
                            </v-simple-table>
                        </div>
                    </v-card>
                    <component-selector
                        v-if="selectedPageId"
                        :clientKey="reportClient"
                        :page-id="selectedPageId"
                        :available-components="availableComponents"
                        @on-select="componentAdd"/>
                </div>
            </v-col>
        </v-row>
        <preview :report-id="reportId" :report-data="previewReportData" v-model="preview" />
        <notification-send-modal
            v-model="notificationSendModal"
            :report-id="reportId"
            :mode="notificationSendMode"
        />
    </request>
</template>

<script>
    import draggable from 'vuedraggable';
    import bus from '@/ebus';
    import ClientSelector from '@/components/User/ClientSelector.vue';
    import { rules } from '@/utils';
    import ReportAPI from './webapi';
    import ReportModel from '../Models/Report';
    import ComponentSelector from './ComponentSelector.vue';
    import ComponentParams from './Params.vue';
    import NotificationSendModal from './NotificationSendModal.vue';
    import PageCreate from './PageCreate.vue';
    import PageItem from './PageItem.vue';
    import Preview from './Preview.vue';

    const deleteFromObjectArray = (arr, key, value) => {
        const index = arr.findIndex((obj) => obj[key] === value);
        arr.splice(index, 1);
    };

    const deleteFromArray = (arr, value) => {
        const index = arr.findIndex((item) => item === value);
        arr.splice(index, 1);
    };

    const deleteFromObject = (obj, key) => {
        const deleteObject = obj;
        delete deleteObject[key];
    };

    export default {
        name: 'ReportBuilder',

        components: {
            draggable,
            ClientSelector,
            ComponentSelector,
            ComponentParams,
            NotificationSendModal,
            PageCreate,
            PageItem,
            Preview,
        },

        props: {
            reportId: {
                type: String,
                default: undefined,
            },
        },

        data: () => ({
            pageOrder: [],
            request: () => ReportAPI.getComponents(),
            availableComponents: {},

            reportTitle: undefined,
            reportClient: undefined,

            pages: [], // ordered list of pageIds
            pageData: {},
            pageComponents: {},
            selectedComponentsData: {},
            reportChanged: true,

            selectedPageId: undefined, // holds the pointer to the active page
            rulesRequired: [rules.required()],

            // NOTIFICATION MODAL
            notificationSendModal: false,
            notificationSendMode: 'author',

            // PREVIEW
            preview: false,
            previewReportData: {},
        }),

        created() {
            if (this.reportId !== undefined) {
                this.retrieveReport();
            }
        },

        computed: {
            selectedComponents() {
                return this.pageComponents[this.selectedPageId];
            },
            selectedPageTitle() {
                if (this.selectedPageId) {
                    return this.pageData[this.selectedPageId].title;
                }
                return undefined;
            },
        },

        methods: {
            pageCreate(page) {
                this.pages.push(page.pid);
                this.$set(this.pageData, page.pid, page);
            },
            pageDelete(pid) {
                // Delete from pages array
                deleteFromArray(this.pages, pid);
                // Delete page data (page title, ...)
                deleteFromObject(this.pageData, pid);
                // Delete components in the page.
                const components = this.pageComponents[pid];
                components.forEach((com) => {
                    deleteFromObject(this.selectedComponentsData, com.cid);
                });
                // Delete components pointer
                deleteFromObject(this.pageComponents, pid);

                if (this.selectedPageId === pid) {
                    this.selectedPageId = undefined;
                }
            },
            pageSelect(pid) {
                this.selectedPageId = pid;
            },
            pageTitleUpdate(pid, title) {
                this.pageData[pid] = { ...this.pageData[pid], ...{ title } };
            },
            saveReport() {
                const report = this.buildReport();
                if (this.reportId !== undefined) {
                    // UPDATE EXISTING REPORT
                    ReportAPI.updateReport(this.reportId, report).then(() => {
                        this.$helpers.notifySuccess('Report sucessfully updated.');
                        this.reportChanged = false;
                    }).catch((err) => {
                        this.$helpers.notifyError(`Report not saved. Error: ${err}`);
                    });
                } else {
                    // CREATE NEW REPORT
                    ReportAPI.createReport(report).then((response) => {
                        this.$helpers.notifySuccess('Report sucessfully created');
                        this.reportChanged = false;
                        const reportId = response.data.id;
                        this.$emit('on-create', reportId);
                    }).catch((err) => {
                        this.$helpers.notifyError(`Report not saved. Error: ${err}`);
                    });
                }
            },
            buildReport() {
                const pages = [];
                this.pages.forEach((pid) => {
                    const page = {
                        pid,
                        title: this.pageData[pid].title,
                    };

                    const components = [];

                    const pageComponents = this.pageComponents[pid] || [];
                    pageComponents.forEach((component) => {
                        const com = {
                            cid: component.cid,
                            key: component.key,
                            data: this.selectedComponentsData[component.cid],
                        };
                        components.push(com);
                    });

                    page.components = components;
                    pages.push(page);
                });

                const report = {
                    title: this.reportTitle,
                    client: this.reportClient,
                    pages,
                };

                return report;
            },
            onRequestSuccess(data) {
                this.availableComponents = data;
            },
            componentAdd(componentKey, pageId) {
                const timestamp = Date.now();
                const cid = `${componentKey}-${timestamp}`;
                if (this.pageComponents[pageId] === undefined) {
                    // Here we need to use $set because we are assigning new reactive
                    // property to the object.
                    this.$set(this.pageComponents, pageId, []);
                }
                this.pageComponents[pageId].push({ cid, key: componentKey });

                // We need to wait that component is renderend then emit an event for openening
                // it for the first time.
                this.$nextTick(() => bus.$emit('report-component-new', cid));
            },
            componentRemove(payload) {
                const { pid, cid } = payload;
                console.log('Remove Cmponent', pid, cid);
                deleteFromObjectArray(this.pageComponents[pid], 'cid', cid);
                deleteFromObject(this.selectedComponents, cid);
            },
            componentUpdate(data, index) {
                this.selectedComponentsData[index] = data;
                this.$forceUpdate();
            },
            retrieveReport() {
                ReportAPI.getReport(this.reportId).then((data) => {
                    const report = new ReportModel(data.report);
                    this.reportTitle = report.getTitle();
                    this.reportClient = report.getClientKey();
                    this.pages = report.getPages();
                    this.pageData = report.getPageData();
                    this.pageComponents = report.getPageComponents();
                    this.selectedComponentsData = report.getComponentsData();
                });
            },
            // PREVIEW
            previewReport() {
                this.previewReportData = { report: this.buildReport() };
                this.preview = true;
            },
        },
    };
</script>

<style scoped>
    iframe {
        width: 60%;
        height: 80vh;
    }

    .drag-handle-comp {
        color: #bebebe;
        cursor: move;
    }
</style>
