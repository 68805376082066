<template>
    <request :request="getClientsRequest" @on-success="onRequestSuccess">
        <v-select
            v-model="selectedClient"
            :items="clients"
            item-text="name"
            item-value="key"
            label="Client"
            persistent-hint
            outlined
            dense
            background-color="white"
            :rules="rules"
        />
    </request>
</template>

<script>
    import constants from '@/constants';
    import webapi from '@/core/webapi';

    const URI = `${constants.API_BASE}/user/clients`;

    export default {
        name: 'ClientSelector',
        props: {
            value: {
                type: [String, Number],
            },
            rules: {
                type: Array,
                default: undefined,
            },
        },
        data: () => ({
            clients: [],
            selectedClient: undefined,
        }),
        watch: {
            value: {
                immediate: true,
                handler(newValue) {
                    this.selectedClient = newValue;
                },
            },
            selectedClient(newValue) {
                this.$emit('input', newValue);
            },
        },
        methods: {
            onRequestSuccess(data) {
                this.clients = data;
            },
            async getClientsRequest() {
                const uri = URI;
                return webapi.get({ uri });
            },
        },
    };
</script>
