<template>
    <v-dialog
        v-model="dialog"
        width="500"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"

                v-bind="attrs"
                v-on="on"
                icon
            >
                <v-icon>{{ '$add' }}</v-icon>
            </v-btn>
            <div>
                <small class="grey--text text--lighten-1">Add component</small>
            </div>
        </template>

        <v-card>
            <v-card-title class="headline grey lighten-2">
                Choose Report component
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col>
                        <v-list dense>
                            <v-subheader>CORE WIDGETS</v-subheader>
                            <v-list-item-group
                                color="primary">
                                <v-list-item
                                    v-for="item in items"
                                    :key="item.key"
                                    @click="emitComponent(item.key)"
                                >
                                    <v-list-item-icon>
                                        <v-chip small :ripple="false" dark>
                                            <b>CORE</b>
                                        </v-chip>
                                    </v-list-item-icon>
                                    <v-list-item-content class="text-left">
                                        <v-list-item-title v-text="item.name" />
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-col>
                </v-row>
                <v-row v-if="clientKey">
                    <v-col>
                        <client-widgets :client-key="clientKey" />
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />
                <v-btn
                    text
                    @click="dialog = false"
                >
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import ClientWidgets from './ClientWidgets.vue';

    export default {
        name: 'ReportComponentSelector',

        components: {
            ClientWidgets,
        },

        props: {
            clientKey: {
                type: String,
                default: undefined,
            },
            availableComponents: {
                type: Object,
                required: true,
            },
            pageId: {
                type: String,
                required: true,
            },
        },

        data: () => ({
            dialog: false,
        }),

        computed: {
            items() {
                const items = [];

                Object.keys(this.availableComponents).forEach((key) => {
                    const item = { key, name: this.availableComponents[key].name };
                    items.push(item);
                });
                return items || [];
            },
        },

        methods: {
            emitComponent(key) {
                this.$emit('on-select', key, this.pageId);
                this.dialog = false;
            },
        },

    };
</script>
