<template>
    <v-dialog
        v-model="modal"
        width="800px"
        height="400px"
    >
        <v-card>
            <v-card-title class="headline grey lighten-2">
                Sending notification to {{ sendTo | snakeToTitleCase }}
            </v-card-title>

            <v-card-text>
                <v-container fluid>
                    <markdown @html="setCustomMessageBefore" />
                </v-container>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />
                <confirm-btn
                    label="Send Notification"
                    :title="confirmTitle"
                    :text="confirmText"
                    @confirm="sendEmail"
                    @cancel="close"
                />
                <v-btn @click="close" outlined class="ml-2">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import ConfirmBtn from '@/components/ConfirmBtn.vue';
    import Markdown from '@/components/Markdown.vue';
    import ReportAPI from './webapi';

    const SEND_TO_CLIENTS = 'clients';
    const SEND_TO_AUTHOR = 'author';

    export default {
        name: 'NotificationSendModal',

        components: {
            ConfirmBtn,
            Markdown,
        },

        props: {
            value: {
                type: Boolean,
                default: false,
            },
            reportId: {
                type: String,
                required: true,
            },
            mode: {
                type: String,
                default: undefined,
            },
        },

        data: () => ({
            modal: false,
            customMessageBefore: undefined,
            textareaWidth: undefined,
        }),

        computed: {
            sendTo() {
                if (this.mode === SEND_TO_CLIENTS) {
                    return SEND_TO_CLIENTS;
                }
                return SEND_TO_AUTHOR;
            },
            confirmTitle() {
                return `Notification fot ${this.sendTo}`;
            },
            confirmText() {
                if (this.sendTo === SEND_TO_CLIENTS) {
                    return 'Confirm you really want to send the notification to CLIENTS??';
                }
                if (this.sendTo === SEND_TO_AUTHOR) {
                    return 'Confirm you really want to send the notification to yourself?';
                }
                throw new Error('The to not implemented');
            },
        },

        watch: {
            value: {
                immediate: false,
                handler(newValue) {
                    this.modal = newValue;
                },
            },
            modal(newValue) {
                this.$emit('input', newValue);
            },
        },

        methods: {
            onWindowResize() {
                const textareaWidth = this.$refs.container && this.$refs.container.offsetWidth;
                if (textareaWidth !== this.textareaWidth) {
                    this.textareaWidth = textareaWidth;
                    this.$refs.textarea.calculateInputHeight();
                }
            },
            setCustomMessageBefore(html) {
                this.customMessageBefore = html;
            },
            close() {
                this.$emit('input', false);
            },
            sendEmail() {
                const { reportId } = this;
                const mode = this.sendTo;
                const messageBefore = this.customMessageBefore;

                ReportAPI.sendEmailNotification(reportId, mode, messageBefore).then((response) => {
                    console.log(response);
                });
            },
        },
    };
</script>
