<template>
    <v-row>
        <v-col>
            <v-card>
                <v-card-title class="grey lighten-3">Write here</v-card-title>
                <v-card-text>
                    <br>
                    <codemirror
                        v-model="text"
                        :options="cmOptions" />
                    <br>
                    <div class="text-right">
                        <v-btn  color="purple" small outlined :href="linkToMarkdownDocs" target="new">
                            <v-icon small left>$help</v-icon>
                            Markdown help
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col class="preview">
            <v-card>
                <v-card-title class="grey lighten-3">Preview here</v-card-title>
                <v-card-text>
                    <br>
                    <div v-html="asHtml" />
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import { codemirror } from 'vue-codemirror';
    import 'codemirror/mode/javascript/javascript';
    import 'codemirror/lib/codemirror.css';
    import 'codemirror/theme/base16-light.css';
    import marked from 'marked';

    export default {
        name: 'WTextArea',

        components: {
            codemirror,
        },

        props: {
            value: {
                type: String,
                default: '',
            },
            label: {
                type: String,
                default: undefined,
            },
        },

        data: () => ({
            text: undefined,
            cmOptions: {
                tabSize: 4,
                mode: 'text/x-markdown',
                theme: 'base16-light',
                lineNumbers: false,
                line: true,
            },
            linkToMarkdownDocs: 'https://www.markdownguide.org/cheat-sheet/',
        }),

        computed: {
            asHtml() {
                return marked(this.text);
            },
        },

        watch: {
            value: {
                handler(newValue) {
                    this.text = newValue;
                },
                immediate: true,
            },
            text(newValue) {
                this.$emit('input', newValue);
                this.$emit('html', this.asHtml);
            },
        },
    };
</script>

<style scoped>
    .textarea {
        height: 500px;
        font-family: 'Roboto Mono', monospace;
        font-size: 12px;

    }
</style>
